import { createSlice } from '@reduxjs/toolkit';

const dataFormSlice = createSlice({
    name: 'dataForm',
    initialState: {
        dataForm: []
    },
    reducers: {
        updateDataForm: (state, action) => {
            console.log('Updating dataForm:', action.payload);
            state.dataForm = action.payload;
        }
    }
});

export const { updateDataForm } = dataFormSlice.actions;
export default dataFormSlice.reducer;

// import { createSlice } from '@reduxjs/toolkit';

// const dataFormSlice = createSlice({
//     name: 'dataForm',
//     initialState: {
//         dataForm: {
//             id: '',
//             battery: '',
//             battery_low: false,
//             contact: false,
//             linkquality: '',
//             tamper: false,
//             voltage: ''
//         }
//     },
//     reducers: {
//         updateDataForm: (state, action) => {
//             console.log('Updating dataForm:', action.payload);
//             state.dataForm = action.payload;
//         }
//     }
// });

// export const { updateDataForm } = dataFormSlice.actions;
// export default dataFormSlice.reducer;