// https://recharts.org/en-US/examples/LineChartWithReferenceLines

import React, { useEffect, useState } from 'react';
import { styled, spacing } from '@material-ui/core/styles';
import {
    Tabs,
    Box,
    Tab,
    TabContext,
    TabPanel,
    TabList,
    TextField,
    Checkbox,
    FormControlLabel,
    Button,
    Grid,
    Paper, Typography, Table, TableHead, TableBody, TableRow, TableCell
} from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush, ReferenceLine, ReferenceArea } from 'recharts';
import store from './../store';
import { updateMeas1, updateMeas2 } from './../reducer';
import { useSelector } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const ZoomLineChart = () => {
    const [data, setData] = useState([]);
    const [brushStartIndex, setBrushStartIndex] = useState(0);
    const [brushEndIndex, setBrushEndIndex] = useState(60);
    const data2 = useSelector((state) => state.data.meas1);
    const dataM2 = useSelector((state) => state.data.meas2);

    useEffect(() => {
        // const intervalId = setInterval(fetchData, 60000); // Запрос будет выполняться каждые 60 секунд

        trackPromise(
            new Promise((resolve, reject) => {
                fetchData().then(resolve).catch(reject);
            })
        );

        // Очистка интервала при размонтировании компонента
        // return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (data2.Result !== undefined) {
            console.log(data);
            console.log('');
            console.log(data2.Result);
            // setData(data2.Result);
            new Promise((resolve, reject) => {
                fetchData().then(resolve).catch(reject);
            })
        }
    }, [data2.Result]);

    // useEffect(() => {
    //     if (data2 !== undefined) {
    //       console.log(data);
    //       console.log('');
    //       console.log(data2.Result);
    //       if (data2.Result !== undefined) {
    //         console.log(data2.Result);
    //         setData(data2.Result);
    //       }
    //     }
    //   }, [data2]);

    const fetchData = async () => {
        try {
            await fetch('/api/getmeasure20minlastday') // http://localhost:5115/api/getmeasure1min')
                .then(response => response.json())
                .then(data => setData(data));
        } catch (error) {
            console.error('Error fetching chart data:', error);
        }
    };

    const handleBrushChange = (event) => {
        const { startIndex, endIndex } = event;
        setBrushStartIndex(startIndex);
        setBrushEndIndex(endIndex);
    };

    // Group data by channelId
    const groupedData = data.reduce((acc, curr) => {
        const channelId = curr.channelId;
        if (!acc[channelId]) {
            acc[channelId] = [];
        }
        acc[channelId].push(curr);
        return acc;
    }, {});

    const lastData = Object.values(groupedData).map(data => data[data.length - 1]);

    function formatDate(dateString) {
        const date = new Date(dateString);
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
        return formattedDate;
    }

    return (
        <div>
            {/* <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Item>
                        {dataM1}
                    </Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>
                        {dataM2}
                    </Item>
                </Grid>
                <Grid item xs={4}>
                    <Item>xs=4</Item>
                </Grid>
                <Grid item xs={4}>
                    <Item>xs=4</Item>
                </Grid>
                <Grid item xs={4}>
                    <Item>xs=4</Item>
                </Grid>
                <Grid item xs={12}>
                    <Item>
                    </Item>
                </Grid>
                <Grid item xs={4}>
                    <Item>xs=4</Item>
                </Grid>
                <Grid item xs={8}>
                    <Item>xs=8</Item>
                </Grid>
            </Grid> */}

            {/* Блоки одинаковой высоты */}
            <Grid container spacing={2}>
                {Object.keys(groupedData).map(channelId => (
                    <Grid container key={channelId} spacing={2}>
                        <Grid item xs={3}>
                            <Box p={2} border={1} borderColor="grey.500" display="flex" alignItems="center" justifyContent="center">
                                <Typography variant="h6">Дата и время: {formatDate(lastData.find(data => data.channelId === channelId).measurementDate)}</Typography>
                                <Typography variant="h6">Last Value: {lastData.find(data => data.channelId === channelId).value}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box p={2} border={1} borderColor="grey.500" display="flex" alignItems="center" justifyContent="center">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Channel ID</TableCell>
                                            <TableCell>Value</TableCell>
                                            <TableCell>Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>{channelId}</TableCell>
                                            <TableCell>{lastData.find(data => data.channelId === channelId).value}</TableCell>
                                            <TableCell>{formatDate(lastData.find(data => data.channelId === channelId).measurementDate)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box p={2} border={1} borderColor="grey.500" display="flex" alignItems="center" justifyContent="center">
                                <ResponsiveContainer width="100%" height={200}>
                                    <LineChart
                                        width={500}
                                        height={200}
                                        data={groupedData[channelId]}
                                        syncId="anyId"
                                        margin={{
                                            top: 10,
                                            right: 30,
                                            left: 0,
                                            bottom: 0,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="measurementDate" />
                                        <YAxis />
                                        <Tooltip />
                                        <ReferenceArea y1={-0.1} y2={1.1} fill="#E5FFD8" alwaysShow />
                                        <ReferenceLine y={0.6} label="ПДК" stroke="#FAB6AF" />
                                        <Line name={channelId} type="natural" dataKey="value" stroke="#8884d8" fill="#8884d8" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Box>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            {/* <Grid container style={{ display: 'flex', alignItems: 'stretch' }}>
                <Grid item xs={6}>
                    <Box p={2} border={1} borderColor="grey.500" display="flex" alignItems="center" justifyContent="center">

                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box p={2} border={1} borderColor="grey.500" display="flex" alignItems="center" justifyContent="center">
                        {Object.keys(groupedData).map(channelId => (
                            <ResponsiveContainer width="100%" height={200} key={channelId}>
                                <LineChart
                                    width={500}
                                    height={200}
                                    data={groupedData[channelId]}
                                    syncId="anyId"
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 0,
                                        bottom: 0,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="measurementDate" />
                                    <YAxis />
                                    <Tooltip />
                                    <Brush
                                        startIndex={brushStartIndex}
                                        endIndex={brushEndIndex}
                                        onChange={(event) => handleBrushChange(event)}
                                    />
                                    <ReferenceArea y1={-0.1} y2={1.1} fill="#E5FFD8" alwaysShow />
                                    <ReferenceLine y={0.6} label="ПДК" stroke="#FAB6AF" />
                                    <Line name={channelId} type="natural" dataKey="value" stroke="#8884d8" fill="#8884d8" />
                                </LineChart>
                            </ResponsiveContainer >
                        ))}
                    </Box >
                </Grid >
            </Grid > */}
        </div >
    );
};

export default ZoomLineChart;

// import React, { useState, useEffect, useRef } from 'react';
// import {
//     LineChart,
//     AreaChart,
//     RadarChart,
//     PolarGrid,
//     PolarAngleAxis,
//     PolarRadiusAxis,
//     Line,
//     Area,
//     Radar,
//     XAxis,
//     YAxis,
//     CartesianGrid,
//     Tooltip,
//     Legend,
//     Brush,
//     ReferenceLine,
//     ReferenceArea,
//     ResponsiveContainer
// } from 'recharts';


// const ZoomLineChart = () => {
//     const [data, setData] = useState([]);
//     const [brushStartIndex, setBrushStartIndex] = useState(0);
//     const [brushEndIndex, setBrushEndIndex] = useState(60);

    // useEffect(() => {
    //     const intervalId = setInterval(fetchData, 5000); // Запрос будет выполняться каждые 5 секунд

    //     // Очистка интервала при размонтировании компонента
    //     return () => clearInterval(intervalId);
    // }, []);

    // const fetchData = async () => {
    //     try {
    //         const response = await fetch('https://api.subbnet.ru/api/chart/redata/600');
    //         const chartData = await response.json();
    //         setData(chartData);
    //     } catch (error) {
    //         console.error('Error fetching chart data:', error);
    //     }
    // };

//     const handleBrushChange = (event) => {
//         const { startIndex, endIndex } = event;
//         setBrushStartIndex(startIndex);
//         setBrushEndIndex(endIndex);
//     };

//     return (
//         <>
//             <ResponsiveContainer width="100%" height={450}>
//                 <LineChart
//                     width={500}
//                     height={200}
//                     data={data.data}
//                     syncId="anyId"
//                     margin={{
//                         top: 10,
//                         right: 30,
//                         left: 0,
//                         bottom: 0,
//                     }}
//                 >
//                     <CartesianGrid strokeDasharray="3 3" />
//                     <XAxis dataKey="name" />
//                     <YAxis />
//                     <Tooltip />

//                     <Brush
//                         startIndex={brushStartIndex}
//                         endIndex={brushEndIndex}
//                         onChange={(event) => handleBrushChange(event)}
//                     />
//                     <ReferenceArea y1={-2.8} y2={11} fill="#E5FFD8" alwaysShow />
//                     <ReferenceLine y={7.7} label="ПДК" stroke="red" />
//                     <Line name='Оксид азота' type="monotone" dataKey="value4" stroke="#FF9640" fill="#FF9640" />
//                     <Line name='Оксид Ашота' type="monotone" dataKey="value5" stroke="#3aff00" fill="#3aff00" />
//                 </LineChart>
//             </ResponsiveContainer>
//         </>
//     );
// };

// export default ZoomLineChart;