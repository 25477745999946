import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import {
    getAllDetails,
    getDetailsById,
    createDetails,
    updateDetails,
    deleteDetails
} from './measuringDeviceSubstancesDetailsService';

const MeasuringDeviceSubstancesDetailsForm = () => {
    const [open, setOpen] = useState(false);
    const [details, setDetails] = useState([]);
    const [minLimit, setMinLimit] = useState('');
    const [maxLimit, setMaxLimit] = useState('');
    const [accuracy, setAccuracy] = useState('');
    const [id, setId] = useState(null);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    useEffect(() => {
        fetchDetails();
    }, []);

    const fetchDetails = () => {
        getAllDetails()
            .then((data) => setDetails(data))
            .catch((error) => console.error('Error fetching details:', error));
    };

    const handleOpen = async (detailId) => {
        if (detailId) {
            try {
                const data = await getDetailsById(detailId);
                setMinLimit(data.minLimit);
                setMaxLimit(data.maxLimit);
                setAccuracy(data.accuracy);
                setId(detailId);
            } catch (error) {
                console.error('Error fetching detail:', error);
            }
        } else {
            setMinLimit('');
            setMaxLimit('');
            setAccuracy('');
            setId(null);
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setId(null);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            id, // Ensure the id is included in the data
            minLimit: parseFloat(minLimit),
            maxLimit: parseFloat(maxLimit),
            accuracy: parseFloat(accuracy)
        };
        if (id) {
            updateDetails(id, data)
                .then(() => {
                    fetchDetails();
                    handleClose();
                })
                .catch((error) => console.error('Error updating detail:', error));
        } else {
            createDetails(data)
                .then(() => {
                    fetchDetails();
                    handleClose();
                })
                .catch((error) => console.error('Error creating detail:', error));
        }
    };

    const handleDeleteConfirmOpen = (detailId) => {
        setDeleteId(detailId);
        setConfirmDeleteOpen(true);
    };

    const handleDeleteConfirmClose = () => {
        setConfirmDeleteOpen(false);
        setDeleteId(null);
    };

    const handleDeleteConfirm = () => {
        deleteDetails(deleteId)
            .then(() => {
                fetchDetails();
                handleDeleteConfirmClose();
            })
            .catch((error) => console.error('Error deleting detail:', error));
    };

    return (
        <div>
            <Button variant="contained" startIcon={<AddIcon />} onClick={() => handleOpen(null)}>
                Добавить
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Нижний предел (мг/м³)</TableCell>
                            <TableCell>Верхний предел (мг/м³)</TableCell>
                            <TableCell>Точность (%)</TableCell>
                            <TableCell align="right">Действия</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {details.map((detail) => (
                            <TableRow key={detail.id}>
                                <TableCell>{detail.minLimit}</TableCell>
                                <TableCell>{detail.maxLimit}</TableCell>
                                <TableCell>{detail.accuracy}</TableCell>
                                <TableCell align="right">
                                    <IconButton onClick={() => handleOpen(detail.id)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDeleteConfirmOpen(detail.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{id ? 'Изменить' : 'Добавить'}</DialogTitle>
                <DialogContent>
                    <Typography variant="caption" display="block" gutterBottom>
                    Пожалуйста, заполните приведенную ниже информацию, чтобы настроить пределы измерения канала и его точность.
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="minLimit"
                        label="Нижний предел (мг/м³)"
                        type="number"
                        fullWidth
                        variant="standard"
                        value={minLimit}
                        onChange={(e) => setMinLimit(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="maxLimit"
                        label="Верхний предел (мг/м³)"
                        type="number"
                        fullWidth
                        variant="standard"
                        value={maxLimit}
                        onChange={(e) => setMaxLimit(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="accuracy"
                        label="Точность (%)"
                        type="number"
                        fullWidth
                        variant="standard"
                        value={accuracy}
                        onChange={(e) => setAccuracy(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отменить</Button>
                    <Button onClick={handleSubmit}>Принять</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={confirmDeleteOpen} onClose={handleDeleteConfirmClose}>
                <DialogTitle>Удаление</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                    Вы уверены, что хотите удалить этот элемент?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteConfirmClose}>Отменить</Button>
                    <Button onClick={handleDeleteConfirm} color="error">Удалить</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default MeasuringDeviceSubstancesDetailsForm;