import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  theme: 'light',
  language: 'en',
  notifications: {
    enabled: true,
    frequency: 'daily',
  },
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    toggleNotifications: (state) => {
      state.notifications.enabled = !state.notifications.enabled;
    },
    setNotificationFrequency: (state, action) => {
      state.notifications.frequency = action.payload;
    },
  },
});

export const { setTheme, setLanguage, toggleNotifications, setNotificationFrequency } = settingsSlice.actions;
export default settingsSlice.reducer;