import { configureStore } from '@reduxjs/toolkit';
import dataReducer from './reducer';
import userReducer from './Slice/userSlice';
import settingsReducer from './Slice/settingsSlice';
import dataFormReducer from './DataForm/dataFormSlice';

const store = configureStore({
  reducer: {
    data: dataReducer,
    user: userReducer,
    settings: settingsReducer,
    dataForm: dataFormReducer,
  },
});

export default store;