import axios from 'axios';

const API_URL = 'https://admin.subbnet.ru/api/measuringDeviceSubstancesDetails';

export const getAllDetails = async () => {
    try {
        const response = await axios.get(API_URL);
        return response.data;
    } catch (error) {
        console.error('Error fetching all details:', error);
        throw error;
    }
};

export const getDetailsById = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching details:', error);
        throw error;
    }
};

export const createDetails = async (data) => {
    try {
        const response = await axios.post(API_URL, data);
        return response.data;
    } catch (error) {
        console.error('Error creating details:', error);
        throw error;
    }
};

export const updateDetails = async (id, data) => {
    try {
        console.log('Updating details with data:', data); // Add this line
        const response = await axios.put(`${API_URL}/${id}`, data);
        return response.data;
    } catch (error) {
        console.error('Error updating details:', error);
        throw error;
    }
};

export const deleteDetails = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting details:', error);
        throw error;
    }
};