import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  meas1: [{}],
  meas2: [],
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    updateMeas1: (state, action) => {
      state.meas1 = action.payload;
    },
    updateMeas2: (state, action) => {
      state.meas2 = action.payload;
    },
  },
});

export const { updateMeas1, updateMeas2 } = dataSlice.actions;
export default dataSlice.reducer;