import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import './index.css';
import App from './App';
import { startConnection } from './signalrService';
import reportWebVitals from './reportWebVitals';
import { usePromiseTracker } from 'react-promise-tracker';
import * as Loader from 'react-loader-spinner';

const root = ReactDOM.createRoot(document.getElementById('root'));

const LoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 9999,
        }}
      >
        <div
          style={{
            padding: '32px',
            borderRadius: '3px',
          }}
        >
          <Loader.ThreeCircles
            height="140"
            width="140"
            color="4fa94d"
            outerCircleColor="#4fa94d"
            innerCircleColor="#4fa94d"
            barColor="#4fa94d"
            ariaLabel="circles-with-bar-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      </div>
    )
  );
};

startConnection();

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <LoadingIndicator />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
