import logo from './logo.svg';
import ZoomLineChart from './Chart/ZoomLineChart';
import Grid from '@mui/material/Grid';
import DataForm from './DataForm/DataForm';
import './App.css';
import MeasuringDeviceSubstancesDetailsForm from './MeasuringDeviceSubstances/MeasuringDeviceSubstancesDetailsForm';

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <h3>Пример графика вещества за месяц, 2160 измерений (3х24х365/12) Recharts библиотека с MIT лицензией</h3>
      </header> */}
      <main>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DataForm />
          </Grid>
          <Grid item xs={12}>
            <MeasuringDeviceSubstancesDetailsForm />
          </Grid>
        </Grid>

        {/* <ZoomLineChart /> */}
      </main>
    </div>
  );
}

export default App;
