import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateDataForm } from './dataFormSlice';
import { Container, Grid, Paper, Typography, Box, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { 
    HelpOutline, 
    StopCircle, 
    Check, 
    Announcement, 
    Construction, 
    WarningAmber,
} from '@mui/icons-material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const DataForm = () => {
    const dataForm = useSelector((state) => state.dataForm.dataForm);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        console.log('DataForm updated:', dataForm);
    }, [dataForm]);

    const getStatusIcon = (status) => {
        switch (status) {
            case 0:
                return <HelpOutline color="disabled" fontSize="large" />;
            case 1:
                return <StopCircle color="error" fontSize="large" />;
            case 2:
                return <Check color="success" fontSize="large" />;
            case 3:
                return <Announcement color="ahtung" fontSize="large" />;
            case 4:
                return <Construction color="ahtung" fontSize="large" />;
            default:
                return null;
        }
    };

    const handleClickOpen = (item) => {
        setSelectedItem(item);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container>
            <Grid container spacing={2}>
                {dataForm.map((item) => (
                    <Grid item xs={12} sm={6} md={4} lg={4} key={item.id}>
                        <Paper elevation={3} style={{ padding: '16px' }}>
                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                ID: {item.id}
                            </Typography>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                <Typography variant="body1" align="left">Battery:</Typography>
                                <Typography variant="body1" align="right">{item.battery}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                <Typography variant="body1" align="left">Battery Low:</Typography>
                                <Checkbox checked={item.battery_low} readOnly />
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                <Typography variant="body1" align="left">Contact:</Typography>
                                <Checkbox checked={item.contact} readOnly />
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                <Typography variant="body1" align="left">Link Quality:</Typography>
                                <Typography variant="body1" align="right">{item.linkquality}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                <Typography variant="body1" align="left">Tamper:</Typography>
                                <Checkbox checked={item.tamper} readOnly />
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                <Typography variant="body1" align="left">Voltage:</Typography>
                                <Typography variant="body1" align="right">{item.voltage}</Typography>
                            </Box>
                            {item.status !== 100 && item.status !== undefined && item.status !== null && (
                                <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                    {getStatusIcon(item.status)}
                                </Box>
                            )}
                            <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                <ResponsiveContainer width="100%" height={150}>
                                    <LineChart data={item.chartData} onClick={() => handleClickOpen(item)}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="Dt" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="Value" stroke="#8884d8" activeDot={{ r: 8 }} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Box>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>Chart Details</DialogTitle>
                <DialogContent>
                    {selectedItem && (
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart data={selectedItem.chartData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="Dt" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="Value" stroke="#8884d8" activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default DataForm;


// import React, { useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { updateDataForm } from './dataFormSlice';
// import { Container, Grid, Paper, Typography, Box, Checkbox } from '@mui/material';
// import { Check, HelpOutline, StopCircle, CheckCircle, Announcement, Build, Construction } from '@mui/icons-material';

// const DataForm = () => {
//     const dataForm = useSelector((state) => state.dataForm.dataForm);
//     const dispatch = useDispatch();

//     useEffect(() => {
//         console.log('DataForm updated:', dataForm);
//     }, [dataForm]);

//     const getStatusIcon = (status) => {
//         switch (status) {
//             case 0:
//                 return <HelpOutline color="disabled" fontSize="large" />;
//             case 1:
//                 return <StopCircle color="error" fontSize="large" />;
//             case 2:
//                 return <Check color="success" fontSize="large" />;
//             case 3:
//                 return <Announcement color="ahtung" fontSize="large" />;   
//             case 4:
//                 return <Construction color="ahtung" fontSize="large" />;                  
//             default:
//                 return null;
//         }
//     };

//     return (
//         <Container>
//             <Grid container spacing={2}>
//                 {dataForm.map((item) => (
//                     <Grid item xs={12} sm={6} md={4} lg={4} key={item.id}>
//                         <Paper elevation={3} style={{ padding: '16px' }}>
//                             <Typography variant="body2" color="textSecondary" gutterBottom>
//                                 ID: {item.id}
//                             </Typography>
//                             <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
//                                 <Typography variant="body1" align="left">Battery:</Typography>
//                                 <Typography variant="body1" align="right">{item.battery}</Typography>
//                             </Box>
//                             <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
//                                 <Typography variant="body1" align="left">Battery Low:</Typography>
//                                 <Checkbox checked={item.battery_low} readOnly />
//                             </Box>
//                             <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
//                                 <Typography variant="body1" align="left">Contact:</Typography>
//                                 <Checkbox checked={item.contact} readOnly />
//                             </Box>
//                             <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
//                                 <Typography variant="body1" align="left">Link Quality:</Typography>
//                                 <Typography variant="body1" align="right">{item.linkquality}</Typography>
//                             </Box>
//                             <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
//                                 <Typography variant="body1" align="left">Tamper:</Typography>
//                                 <Checkbox checked={item.tamper} readOnly />
//                             </Box>
//                             <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
//                                 <Typography variant="body1" align="left">Voltage:</Typography>
//                                 <Typography variant="body1" align="right">{item.voltage}</Typography>
//                             </Box>
//                             {item.status !== 100 && item.status !== undefined && item.status !== null && (
//                                 <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
//                                     {getStatusIcon(item.status)}
//                                 </Box>
//                             )}
//                         </Paper>
//                     </Grid>
//                 ))}
//             </Grid>
//         </Container>
//     );
// };

// export default DataForm;