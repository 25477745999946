import * as signalR from '@microsoft/signalr';
import store from './store';
import { updateMeas1, updateMeas2 } from './reducer';
import { updateDataForm } from './DataForm/dataFormSlice';

const connection = new signalR.HubConnectionBuilder()
    .withUrl('/hub')
    .build();

connection.on('Meas1', (data) => {
    console.log(data)
    store.dispatch(updateMeas1(JSON.parse(data)));
});

connection.on('Meas2', (data) => {
    console.log(data)
    store.dispatch(updateMeas2(data));
});

connection.on('DataForm', (data) => {
    console.log('Received data:', data);
    // Распарсивание строки JSON
    const parsedData = JSON.parse(data);
    console.log('Parsed data:', parsedData);
    store.dispatch(updateDataForm(parsedData));
});

// connection.on('DataForm', (data) => {
//     console.log(data);
//     // Преобразование данных, если необходимо
//     const transformedData = {
//         battery: data.battery,
//         battery_low: data.battery_low,
//         contact: data.contact,
//         linkquality: data.linkquality,
//         tamper: data.tamper,
//         voltage: data.voltage
//     };
//     store.dispatch(updateDataForm(transformedData));
// });

export const startConnection = async () => {
    try {
        await connection.start();
        console.log('SignalR Connected.');
    } catch (err) {
        console.log(err);
        setTimeout(startConnection, 5000);
    }
};

export const stopConnection = async () => {
    try {
        await connection.stop();
        console.log('SignalR Disconnected.');
    } catch (err) {
        console.log(err);
    }
};